.home {
    min-height: 89vh; /* Ensure the section covers the full height of the viewport */
    display: flex;
    align-items: center;
    margin-top: 30px;
    /* flex-direction: row; */
    /* padding: 20px; Optional padding for spacing */
}

.content {
    flex: 1;
    padding: 30px 60px; /* 30px top and bottom, 60px left and right */
    box-sizing: border-box; /* Ensure padding does not affect total width */
  }

  #root > div > div > div > div {
    align-items: center;
  }

.home-image {
    width: 100%;
    max-width: 400px;
    height: auto;
    border-radius: 8px;
}

.home h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
    text-align: left;
}

.home h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    font-weight: 600;
    text-align: left;
}

.home h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
    font-weight: 500;
    text-align: left;
    color: #F6B17A;
}

.home h4 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    font-weight: 400;
    text-align: left;
}

.home p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    line-height: 1.6;
    color: #f8f9fa; 
    text-align: left;
}

.home .btn {
    padding: 0.8rem 1.5rem;
    background-color: #F6B17A;
    color: #344955;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.home .btn:hover {
    background-color: #FBFADA;
}

@media (max-width: 768px) {
    .home h1 {
        font-size: 2.5rem;
    }

    .home h2 {
        font-size: 2rem;
    }

    .home h3 {
        font-size: 1.75rem;
        color: #31363F;
    }

    .home h4 {
        font-size: 1.25rem;
    }

    .home p {
        font-size: 1rem;
    }

    .home-image {
        max-width: 100%;
    }
}

.bg-primary {
    background-color: #344955 !important;
    width: 80%;
    height: 60%;
    position: relative;
    left: 10%; 
    border-radius: 18px;
}
