html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

p {
  align-items: left;
}

.content {
  flex: 1;
  padding: 30px 60px; 
  box-sizing: border-box;
}

#root > div > div > div {
  padding: 0px;
}

#root > div > div {
  padding-top: 0px;
}

.about-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #344955;
  padding: 20px;
  border-radius: 10px;
  width: 100%; 
  margin: auto; 
}

.about-image {
  width: 100%;
  max-width: 450px; 
  height: auto;
  border-radius: 8px;
}

.about p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  line-height: 1.6;
  color: #f8f9fa; 
  text-align: left;
}
#root > div > footer > div > div > div > p {
  text-align: center;
}

.button-group .btn {
  padding: 0.8rem 1.5rem;
  background-color: #F6B17A;
  color: #344955;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  margin-right: 50px; 
  margin-bottom: 10px; 
}

#root > div > div > div > div > div > div:nth-child(2) > div {
  align-items: left;
}

.button-group .btn:last-child {
  margin-right: 0; 
}

.button-group {
  justify-content: center; 
}

.button-group .btn:hover {
  background-color: #FBFADA;
}

@media (max-width: 768px) {
  .content {
    padding: 30px 30px; 
  }

  .about-container {
    width: 100%; 
    text-align: center;
  }

  .about-image {
    margin-bottom: 20px;
  }

  p {
    font-size: 1rem;
  }

  .button-group .btn {
    margin-right: 10px; 
  }
}
