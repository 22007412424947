html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
  display: flex;
  padding: 30px;
  flex-direction: column;
}

.footer {
  background-color: #222831;
  color: white;
  text-align: center;
  padding: 20px 0;
  margin-top: 10vh;
  position: static;
  bottom: 0;
  width: 100%;
}

.footer p {
  margin: 0;
  font-size: 1.4rem; 
}

.footer .social-links {
  margin-top: 10px;
}

.footer .social-links a {
  color: white;
  margin: 0 25px; 
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}

.footer .social-links a:hover {
  text-decoration: underline;
}

.footer .social-icon {
  margin-right: 8px; 
  font-size: 2.2rem; 
}
