.custom-navbar {
    background-color: #222831;
    padding: 0.5rem 1rem;
  }

  button.navbar-toggler.collapsed {
    background-color: #FFFFFF;
  }

  button.navbar-toggler {
    background-color: #FFFFFF;
  }
  
  .navbar-brand {
    font-size: 1.5rem;
    font-weight: bold;
    color: #FFFFFF !important; 
  }
  
  .nav-links {
    flex-basis: 40%; 
    display: flex;
    justify-content: flex-end; 
  }
  
  .nav-link {
    color: #CCCCCC !important; 
    margin-left: 4rem; 
    transition: color 0.3s ease;
    white-space: nowrap; 
  }
  
  .nav-link:hover {
    color: #F6B17A !important; 
  }
  
  body {
    background-color: #31363F; 
    color: #FFFFFF; 
  }
   
  
  @media (max-width: 767px) {
    .nav-links {
      flex-basis: 100%; 
      justify-content: center; 
    }
    .nav-link {
      margin-left: 0;
      margin-bottom: 0.5rem;
    }
  }
  